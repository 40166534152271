.nested-list-container{
    position: relative;
}
ul.nested-list{
    list-style-type: none;
    padding: 0;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 5px;
    margin-top: 2px;
    background-color: white;
    position: absolute;
    width: 100%;
    z-index: 9;
    // box-shadow: -1px 1px 1px 1px rgba(0 0 0, 0.05);
}
.nested-list-container ul.nested-list{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
li.nested-list-item{
    vertical-align: middle;
    padding: 0 1em 0 1.5em;
    cursor: pointer;
}
li.nested-list-item p{
    margin-bottom: 0;
}

li.nested-list-item.nested-root:hover{
    background-color: #deebff;
}
li.nested-list-item .nested-list-name-highlight:hover{
    background-color: #deebff;
}
li.nested-list-item .nested-list-arrow-highlight:hover{
    background-color: #deebff;
}
li.nested-list-item .nested-list-search:hover{
    background-color: #deebff;
}
li.nested-list-item.nested-list-header p{
    overflow-y:hidden;
    overflow-x: scroll;
    white-space: nowrap;
}
li.nested-list-item .nested-list-name, .nested-list-arrow{
    border-radius:5px;
}

li.nested-list-item.nested-list-header .nested-list-header-item:hover{
    background-color: $gray-300;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height:5px;
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1; 
// }
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body[data-theme="dark"]{
    background-color: #434c69;
}

.dark h1, .dark h2, .dark h3, .dark h4 .dark h5, .dark h6, .dark p, .dark label{
    color: white;
}

.dark .page-content{
    background-color: #434c69;
}
.dark .card-body, .dark .card{
    background-color: #525c80;
}

.dark #preloader{
    background-color: #434c69;
}
.dark .page-content input, .dark .account-pages input{
    background-color: #2a3042;
    border-color: #1e2330;
    color: white;
}